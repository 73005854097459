import styled from '@emotion/styled';

export default styled.div`
    position: relative;
    padding: 1.2em 0 2em 0;
    width: 100%;

    .breadcrumbs-list {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        overflow-x: auto;

        .breadcrumb {
            &:last-of-type {
                pointer-events: none;
            }
        }

        .breadcrumb {
            font-size: .7em;
            color: #646464;
            font-weight: 400;
            width: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            .separator {
                margin: 0 .5em;
            }

            a {
                color: black;
                text-decoration: none;
                cursor: pointer;
                transition: .3s opacity ease-in-out;
                white-space: nowrap;

                &:hover {
                    opacity: .6;
                }
            }

            .active-label {
                font-weight: bold;
            }
        }
    }
`;
