import { Props as SectionCheckoutProps } from './sections/Checkout/types';
import { Props as SectionContactProps } from './sections/Contact/types';
import { Props as SectionHeroProps } from './sections/Hero/types';
import { Props as SectionProductsProps } from './sections/Products/types';
import Component from './component';

export interface PageContent {
    hero: SectionHeroProps;
    contact: SectionContactProps;
    checkout: SectionCheckoutProps;
    products: SectionProductsProps;
}

export default Component;
