import styled from '@emotion/styled';

import variables from 'theme/styles/vars';

export default styled.div`
    .redirect-button {
        margin-top: 3em;
    }

    @media all and (max-width: ${variables.tabletS}) {
    }
`;