import { useContext, useEffect, useState } from 'react';
import { NextPage } from 'next';

import Element from 'types/redux/Element';
import { Routes } from 'types/routes';
import { Section } from 'types/sections';

import CmsPage from 'models/CmsPage';
import CmsSection from 'models/CmsSection';

import { ELEMENT_MARKUP } from 'consts/redux';

import { single as singleCmsPage } from 'requests/cmsPages';

import LocaleContext from 'components/context/Locale';
import Breadcrumbs from 'components/layout/Breadcrumbs/component';
import Button, { ButtonElements } from 'components/layout/Button';
import Head from 'components/layout/Head';
import PageContainer from 'components/layout/PageContainer';
import SectionsWrapper from 'components/modules/public/cms/SectionsWrapper';
import SectionWrapper from 'components/modules/public/cms/SectionWrapper';
import WrapperPublic from 'components/wrappers/Public';

import SectionCheckout from './sections/Checkout';
import SectionHero from './sections/Hero';
import SectionProducts from './sections/Products';
import StyledComponent from './styles';

const PublicPageNotFound: NextPage = () => {
    const localeContext = useContext(LocaleContext);
    const translations = localeContext?.translations?.notfound;
    const translationsBreadcrumbs = localeContext?.translations?.breadcrumbs;
    const [cmsPage, setCmsPage] = useState<Element<CmsPage>>({ ...ELEMENT_MARKUP, isLoading: true });

    const breadcrumbs = [{
        to: Routes.PublicHome,
        label: translationsBreadcrumbs.home,
    }, {
        label: translationsBreadcrumbs.notFound,
        active: true,
    }];

    const sections: Section[] = [
        {
            slug: 'hero',
            order: 1,
            visible: true,
            children: (slug: string, cmsSection: CmsSection) => (
                <SectionWrapper
                    cmsSection={cmsSection}
                    key={slug}
                >
                    {(locale, override) => (
                        <SectionHero
                            override={() => override({
                                bottomChildren: (
                                    <Button
                                        href='/'
                                        className="redirect-button"
                                        element={ButtonElements.Anchor}
                                    >
                                        {translations?.content?.hero?.buttonLabel}
                                    </Button>
                                ),
                            })}
                        />
                    )}
                </SectionWrapper>
            ),
        },
        {
            slug: 'breadcrumbs',
            order: 2,
            visible: true,
            children: (slug: string, cmsSection: CmsSection) => (
                <SectionWrapper
                    cmsSection={cmsSection}
                    key={slug}
                >
                    {(locale) => (<Breadcrumbs breadcrumbs={breadcrumbs} />)}
                </SectionWrapper>
            ),
        },
        {
            slug: 'checkout',
            order: 3,
            visible: true,
            children: (slug: string, cmsSection: CmsSection) => (
                <SectionWrapper
                    cmsSection={cmsSection}
                    key={slug}
                >
                    {(locale, override) => <SectionCheckout override={override} />}
                </SectionWrapper>
            ),
        },
        {
            slug: 'products',
            order: 4,
            visible: true,
            children: (slug: string, cmsSection: CmsSection) => (
                <SectionWrapper
                    cmsSection={cmsSection}
                    key={slug}
                >
                    {(locale, override) => <SectionProducts override={override} />}
                </SectionWrapper>
            ),
        },
    ];

    const loadData = async (): Promise<any> => {
        try {
            await Promise.all([
                singleCmsPage({ slug: 'notFound' }),
            ]).then((responses) => {
                setCmsPage(responses[0]);
            });
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        loadData();
    }, []);

    return (
        <WrapperPublic
            isBannerVisible={cmsPage?.data?.isBannerVisible}
            bannerContent={cmsPage?.data?.bannerContent}
            bannerLink={cmsPage?.data?.bannerLink}
        >
            <StyledComponent className="public-page-home">
                <Head
                    title={cmsPage?.data?.locale?.title || translations?.meta?.title}
                    description={cmsPage?.data?.locale?.description || translations?.meta?.description}
                    keywords={cmsPage?.data?.locale?.keywords || translations?.meta?.keywords}
                    tags={cmsPage?.data?.head}
                    cmsPageIndexingEnabled={cmsPage?.data?.indexingEnabled}
                />
                <PageContainer>
                    {cmsPage?.isLoaded === true && (
                        <SectionsWrapper
                            sections={sections}
                            cmsSections={cmsPage?.data?.sections}
                        />
                    )}
                </PageContainer>
            </StyledComponent>
        </WrapperPublic>
    );
};

export default PublicPageNotFound;
