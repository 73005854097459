import { FunctionComponent } from 'react';

import StyledComponent from './styles';
import { Props } from './types';

const PublicPageNotFoundSectionHero: FunctionComponent<Props> = ({ override }) => {
    return (
        <StyledComponent className="public-page-not-found-section-hero">
            {override()}
        </StyledComponent>
    );
};

export default PublicPageNotFoundSectionHero;
