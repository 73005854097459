import { FunctionComponent } from 'react';

import StyledComponent from './styles';
import { Props } from './types';

const PublicPagePriceListSectionProducts: FunctionComponent<Props> = ({ override }) => {
    const hasOverride = override ? 'p-0' : '';

    return (
        <StyledComponent className={`public-page-price-list-section-products ${hasOverride}`}>
            {override()}
        </StyledComponent>
    );
};

export default PublicPagePriceListSectionProducts;
