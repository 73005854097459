import React, { FunctionComponent } from 'react';
import classnames from 'classnames';

import LayoutContainer from 'components/layout/LayoutContainer';

import StyledComponent from './styles';
import { Props } from './types';

const LayoutBreadcrumbs: FunctionComponent<Props> = ({ breadcrumbs }) => {
    const filteredBreadcrumbs = breadcrumbs.filter(breadcrumb => breadcrumb && breadcrumb.label);
    if (!filteredBreadcrumbs.length) return null;

    return (
        <StyledComponent className="breadcrumbs-component">
            <LayoutContainer>
                <ul
                    className="breadcrumbs-list"
                    itemScope
                    itemType="http://schema.org/BreadcrumbList"
                >
                    {filteredBreadcrumbs.map((breadcrumb, index) => (
                        <li
                            key={breadcrumb.to + breadcrumb.label}
                            className={classnames(
                                'breadcrumb',
                                { active: breadcrumb.active || false }
                            )}
                            itemScope
                            itemProp="itemListElement"
                            itemType="http://schema.org/ListItem"
                        >
                            {index !== 0 && (
                                <span className="separator">/</span>
                            )}
                            <a
                                key={breadcrumb.to}
                                href={breadcrumb.to || ''}
                                onClick={(e) => {
                                    if (breadcrumb.onClick) {
                                        e.preventDefault();
                                        breadcrumb.onClick();
                                    }
                                }}
                                itemProp="item"
                            >
                                <>
                                    <meta
                                        itemProp="position"
                                        content={(index + 1).toString()}
                                    />
                                    <span
                                        itemProp="name"
                                        className={classnames({ ['active-label']: breadcrumb.active || false })}
                                    >
                                        {breadcrumb.label}
                                    </span>
                                </>
                            </a>
                        </li>
                    ))}
                </ul>
            </LayoutContainer>
        </StyledComponent>
    );
};

export default LayoutBreadcrumbs;
